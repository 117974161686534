<template>
  <div name="index">
    <head-box />
    <!-- <img src="../assets/tab-pic.jpg" class="banner" alt="" /> -->
    <div v-if="!isIndex" @click="goBack" class="backBtn">
      <span> {{ $t('public.goBack') }} </span>
    </div>
    <keep-alive>
      <router-view />
    </keep-alive>
  </div>
</template>

<script>
import HeadBox from '../components/HeadBox/index'
export default {
  name: "app",
  components: { HeadBox },
  watch: {
    $route() {
      this.isIndex = this.$route.path === "/casually_index";
    },
  },
  data: () => ({
    isIndex: true,
  }),
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
<style scoped>
.backBtn {
  position: fixed;
  right: 5%;
  top: 90%;
  z-index: 2000;
  opacity: 0.9;
}

.backBtn span {
  color: #b51414;
  font-size: 14px;
  background: #fe9b4a;
  padding: 0.3rem 0.8rem;
  border-radius: 0.5rem;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  font-weight: bold;
}
</style>
